// Notes on the classes:
//
// 1. .carousel.pointer-event should ideally be pan-y (to allow for users to scroll vertically)
//  even when their scroll action started on a carousel, but for compatibility (with Firefox)
//  we're preventing all actions instead
// 2. The .carousel-item-left and .carousel-item-right is used to indicate where
//  the active slide is heading.
// 3. .active.carousel-item is the current slide.
// 4. .active.carousel-item-left and .active.carousel-item-right is the current
//  slide in its in-transition state. Only one of these occurs at a time.
// 5. .carousel-item-next.carousel-item-left and .carousel-item-prev.carousel-item-right
//  is the upcoming slide in transition.

.carousel {
 position: relative;
}

.carousel.pointer-event {
 touch-action: pan-y;
}

.carousel-inner {
 position: relative;
 width: 100%;
 overflow: hidden;
 @include clearfix();
}

.carousel-item {
 position: relative;
 display: none;
 float: left;
 width: 100%;
 margin-right: -100%;
 backface-visibility: hidden;
 @include transition($carousel-transition);
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
 display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
 transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
 transform: translateX(-100%);
}


//
// Alternate transitions
//

.carousel-fade {
 .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
 }

 .carousel-item.active,
 .carousel-item-next.carousel-item-left,
 .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
 }

 .active.carousel-item-left,
 .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  @include transition(0s $carousel-transition-duration opacity);
 }
}


//
// Left/right controls for nav
//

.carousel-control-prev,
.carousel-control-next {
 position: absolute;
 top: 0;
 bottom: 0;
 z-index: 1;
 // Use flex for alignment (1-3)
 display: flex; // 1. allow flex styles
 align-items: center; // 2. vertically center contents
 justify-content: center; // 3. horizontally center contents
 width: $carousel-control-width;
 color: $carousel-control-color;
 text-align: center;
 opacity: $carousel-control-opacity;
 @include transition($carousel-control-transition);

 // Hover/focus state
 @include hover-focus {
  color: $carousel-control-color;
  text-decoration: none;
  outline: 0;
  opacity: $carousel-control-hover-opacity;
 }
}
.carousel-control-prev {
 left: 0;
 @if $enable-gradients {
  background: linear-gradient(90deg, rgba($black, .25), rgba($black, .001));
 }
}
.carousel-control-next {
 right: 0;
 @if $enable-gradients {
  background: linear-gradient(270deg, rgba($black, .25), rgba($black, .001));
 }
}

// Icons for within
.carousel-control-prev-icon,
.carousel-control-next-icon {
 display: inline-block;
 width: $carousel-control-icon-width;
 height: $carousel-control-icon-width;
 background: no-repeat 50% / 100% 100%;
}
.carousel-control-prev-icon {
 background-image: $carousel-control-prev-icon-bg;
}
.carousel-control-next-icon {
 background-image: $carousel-control-next-icon-bg;
}


// Optional indicator pips
//
// Add an ordered list with the following class and add a list item for each
// slide your carousel holds.

.carousel-indicators {
 position: absolute;
 right: 0;
 bottom: 0;
 left: 0;
 z-index: 15;
 display: flex;
 justify-content: center;
 padding-left: 0; // override <ol> default
 // Use the .carousel-control's width as margin so we don't overlay those
 margin-right: $carousel-control-width;
 margin-left: $carousel-control-width;
 list-style: none;

 li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: $carousel-indicator-width;
  height: $carousel-indicator-height;
  margin-right: $carousel-indicator-spacer;
  margin-left: $carousel-indicator-spacer;
  text-indent: -999px;
  cursor: pointer;
  background-color: $carousel-indicator-active-bg;
  background-clip: padding-box;
  // Use transparent borders to increase the hit area by 10px on top and bottom.
  border-top: $carousel-indicator-hit-area-height solid transparent;
  border-bottom: $carousel-indicator-hit-area-height solid transparent;
  opacity: .5;
  @include transition($carousel-indicator-transition);
 }

 .active {
  opacity: 1;
 }
}


// Optional captions
//
//

.carousel-caption {
 position: absolute;
 right: (100% - $carousel-caption-width) / 2;
 bottom: 20px;
 left: (100% - $carousel-caption-width) / 2;
 z-index: 10;
 padding-top: 20px;
 padding-bottom: 20px;
 color: $carousel-caption-color;
 text-align: center;
}
