// stylelint-disable property-blacklist
// Single side border-radius

@mixin border-radius($radius: $border-radius, $fallback-border-radius: false) {
 @if $enable-rounded {
  border-radius: $radius;
 }
 @else if $fallback-border-radius != false {
  border-radius: $fallback-border-radius;
 }
}

@mixin border-top-radius($radius) {
 @if $enable-rounded {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
 }
}

@mixin border-right-radius($radius) {
 @if $enable-rounded {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
 }
}

@mixin border-bottom-radius($radius) {
 @if $enable-rounded {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
 }
}

@mixin border-left-radius($radius) {
 @if $enable-rounded {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
 }
}

@mixin border-top-left-radius($radius) {
 @if $enable-rounded {
  border-top-left-radius: $radius;
 }
}

@mixin border-top-right-radius($radius) {
 @if $enable-rounded {
  border-top-right-radius: $radius;
 }
}

@mixin border-bottom-right-radius($radius) {
 @if $enable-rounded {
  border-bottom-right-radius: $radius;
 }
}

@mixin border-bottom-left-radius($radius) {
 @if $enable-rounded {
  border-bottom-left-radius: $radius;
 }
}
